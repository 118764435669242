import React from 'react'

import { animated, config, useSpring } from '@react-spring/web'

import { useState } from 'react'
import ContentServices from './contentservices'
import fondo from '../recursos/videofondooscuro.mp4'
import certi_1 from '../recursos/Certi-ASTFC.png'
import certi_2 from '../recursos/Certi-SFPC.png'
import certi_3 from '../recursos/Certi-ISO9001.png'

function Inicio(props) {
  const { obj, index } = props
  const [opcion, setOpcion] = useState(false)
  const [opcion2, setOpcion2] = useState(false)

  const btnvermas = useSpring({
    from: { height: opcion ? '55px' : '60px', width: opcion ? '80%' : '90%' },
    to: { height: opcion ? '60px' : '55px', width: opcion ? '90%' : '80%' },
    config: { mass: 1, tension: 120, friction: 14 }
  })
  const btnconocenos = useSpring({
    from: { height: opcion2 ? '55px' : '60px', width: opcion2 ? '85%' : '95%' },
    to: { height: opcion2 ? '60px' : '55px', width: opcion2 ? '95%' : '85%' },
    config: { mass: 1, tension: 120, friction: 14 }
  })

  return (
    <div className='text-center'>
      <header className='container-fluid App-section' role='banner'>
        <div className='header-video'>
          <video autoPlay loop muted playsInline className='' aria-label='Video de fondo'>
            <source src={fondo} type='video/mp4' />
          </video>
        </div>
        <div className='header-overlay'></div>
        <div className='container-xxl mx-auto'>
          <div className='row px-0 py-4 p-md-4'>
            <div className='col-md-1'></div>
            <div data-aos='zoom-in' data-aos-duration='1000' className='col-md-10'>
              <h1 className='display-1 fw-bolder text-white col-md-12 TitleP'>
                Q-EXTREME SAS SERVICIOS REMOTOS DE QA
              </h1>
            </div>
            <p
              data-aos='zoom-in'
              data-aos-duration='1500'
              className='display-7 col-md-12 text-white fw-bolder TitleP'
            >
              FORTALECIMIENTO DE BUENAS PRÁCTICAS PARA SUS PROYECTOS DE TECNOLOGÍA Y DESARROLLO DE
              SOFTWARE.
            </p>
            <div
              data-aos='flip-up'
              data-aos-duration='2000'
              className='container-fluid col-12 col-md-3'
            >
              <a href='#Section1'>
                <animated.button
                  className='hoverbutton rounded-pill bg-transparent border-white mb-5 text-white p-3'
                  onMouseOver={() => setOpcion(true)}
                  onMouseOut={() => setOpcion(false)}
                  style={btnvermas}
                  aria-label='Ver más información'
                >
                  VER MÁS
                </animated.button>
              </a>
            </div>
          </div>
        </div>
      </header>

      <main>
        <section
          id='Section1'
          className='container-fluid col-md-12 App-section2 bg-body-tertiary'
          aria-labelledby='latest-posts'
        >
          <div className='header-overlay2'></div>
          <div
            className='flex justify-content-center align-items-center p-5'
            style={{ height: 520 }}
          >
            <h2 id='latest-posts' className='display-5 my-3'>
              ÚLTIMAS PUBLICACIONES
            </h2>
            <div
              className='elfsight-app-fc8c3c52-d458-457b-afad-b2094e6fded9'
              data-elfsight-app-lazy
              role='complementary'
              aria-label='Aplicación de publicaciones recientes'
            ></div>
          </div>
        </section>

        <section className='container-fluid col-md-12 App-section2'>
          <div className='header-video3'>
            <div className='hero'>
              {[...Array(6)].map((_, i) => (
                <div className='cube' key={i}></div>
              ))}
            </div>
          </div>
          <div className='header-overlay3'></div>
          <ContentServices />
        </section>

        <section
          data-aos='zoom-in'
          className='container-fluid col-md-12 row border-4'
          aria-labelledby='service-flexibility'
        >
          <div className='header-video2'>
            <div className='hero2'>
              {[...Array(6)].map((_, i) => (
                <div className='cube2' key={i}></div>
              ))}
            </div>
          </div>
          <div className='container-fluid mt-5'>
            <h2 id='service-flexibility' className='display-5 col-md-12'>
              FLEXIBILIDAD DEL SERVICIO
            </h2>
            <div className='row px-0 py-4 mt-5'>
              <p className='display-7 col-md-12'>
                Uno de nuestros principales valores diferenciadores es la flexibilidad, lo que le
                permite a Q-Extreme SAS definir estrategias que se adaptan a las necesidades de cada
                uno de los proyectos de tecnología. De esta manera se logra estructurar servicios en
                diferentes modalidades como, tiempo completo, medio tiempo y horas, etc. Permítenos
                crear una estrategia personalizada que se ajuste a tus necesidades.
              </p>
              <div
                data-aos='flip-up'
                className='container-fluid col-12 col-sm-10 col-md-6 col-xl-3'
              >
                <a href='./contactanos'>
                  <animated.button
                    className='hoverbutton shadow p-3 mb-5 rounded-5 mt-5'
                    onMouseOver={() => setOpcion2(true)}
                    onMouseOut={() => setOpcion2(false)}
                    style={btnconocenos}
                    aria-label='Contactarnos'
                  >
                    CONTACTANOS
                  </animated.button>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className='App-section3'>
          <div className='header-video2'>
            <div className='hero'>
              {[...Array(6)].map((_, i) => (
                <div className='cube' key={i}></div>
              ))}
            </div>
          </div>
          <div className='shadow-lg col-12 text-center'>
            <div className='container-fluid px-0 py-5 p-lg-5 T text-white col-8'>
              <h2 className='display-4'>COMPROMISO SOCIAL</h2>
              <p className='mt-5'>
                Apoyamos la inclusión laboral de profesionales con diversidad funcional. En el 2020
                fuimos ganadores de la convocatoria 76 del fondo emprender del SENA para
                emprendedores con diversidad funcional.
              </p>
            </div>
          </div>
        </section>

        <section
          data-aos='zoom-in'
          className='container-fluid col-md-12 border-4'
          aria-labelledby='certifications'
        >
          <div className='header-video2'>
            <div className='hero2'>
              {[...Array(6)].map((_, i) => (
                <div className='cube2' key={i}></div>
              ))}
            </div>
          </div>

          <div className='container-fluid p-4'>
            <div className='mt-5 '>
              <span className=' Tcerti col-md-12 '>CERTIFICACIONES</span>

              <div className='container-fluid  p-4 mt-5 col-12 col-sm-12 col-md-12 col-xl-12'>
                <div className=' d-flex justify-content-center align-items-center gap-5 flex-column flex-md-row'>
                  <div className='d-flex flex-column justify-content-center align-items-center '>
                    <img
                      src={certi_1}
                      className='certificados '
                      alt='Certificado Accredited Software Testing Fundamentals'
                    />
                    <span className='P text-center'>
                      Accredited Software Testing <br />
                      Fundamentals
                    </span>
                  </div>

                  <div className='d-flex flex-column justify-content-center align-items-center'>
                    <img
                      src={certi_2}
                      className='certificados'
                      alt='certificado Scrum Foundation Professional'
                    />
                    <span className=' P '>Scrum Foundation Professional</span>
                  </div>

                  <div className='d-flex flex-column justify-content-center align-items-center'>
                    <img
                      src={certi_3}
                      className='certificados '
                      alt='certificado ISO 9001 Certification'
                    />
                    <span className=' P'>ISO 9001 Certification</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}
export default Inicio
