import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../recursos/logoP.png'
import {
  Email,
  Facebook,
  Intagram,
  Linkendin,
  Location,
  Phone,
  Whatsapp
} from '../recursos/icon/icon'

// export const Footerprincipal = () => {

//   return (
//     <footer className=' container-fluid'>
//       <div className='container-fluid row justify-content-center'>
//         <div className='col-2'></div>
//         <div className='row col-md-4 mt-4 p-4 '>
//           <a className='text-decoration-none Tfooter' to='./Inicio'>
//             INICIO
//           </a>
//           <a className='text-decoration-none Tfooter' to='./nosotros'>
//             NOSOTROS
//           </a>
//           <a className='text-decoration-none Tfooter' to='./nuestros_servicios'>
//             NUESTROS SERVICIOS
//           </a>
//           <a className='text-decoration-none Tfooter' to='./contactanos'>
//             CONTÁCTENOS
//           </a>
//           <a className='text-decoration-none Tfooter' to='./leyDatos'>
//             LEY DE TRATAMIENTO DE DATOS
//           </a>
//         </div>

//         <div className='col-md-4 mt-4 p-4 '>
//           <label className='col-12 Tfooter'>Ubicacion:</label>

//           <iframe
//             className='col-12 col-md-6'
//             src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1991.2107970898319!2d-76.50815019065182!3d3.4893775155671674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e30a9b50300043b%3A0xf7540a8e25a6356e!2sQ-Extreme!5e0!3m2!1ses-419!2sco!4v1696650689713!5m2!1ses-419!2sco'
//             width='300'
//             height='200'
//             loading='lazy'
//           ></iframe>
//         </div>
//       </div>
//       <div className='col-md-12 text-center'>
//         <a className='TCfooter'>©{new Date().getFullYear()} Copyright: www.q-extreme.com</a>
//       </div>
//     </footer>
//   )
// }

export const Footerprincipal = () => {
  return (
    <footer className='text-white' style={{ backgroundColor: '#04a4cc' }}>
      <div className='container py-5'>
        <div className='row g-4'>
          <div className='col-md-4'>
            {/* <div className="h5 mb-3 bg-white">
            <img
                src={logo}
                width={150}
                alt="Logo de Q-extreme"
              />
            </div> */}
            <h4 className='h6 mb-3'>Redes sociales</h4>
            {/* <p>Ensuring quality in every line of code.</p> */}
            <div className='d-flex gap-3'>
              <Link to='https://www.facebook.com/Qextreme' target="_blank" className='text-light'>
                <Facebook />
              </Link>
              <Link to='https://www.linkedin.com/company/q-extreme/' target="_blank" className='text-light'>
                <Linkendin />
              </Link>
              <Link to='https://api.whatsapp.com/send?phone=573125594482' target="_blank" className='text-light'>
                <Whatsapp />
              </Link>
              <Link to='https://www.instagram.com/qextremetecnology/?igshid=NzZhOTFlYzFmZQ%3D%3D' target="_blank" className='text-light'>
                <Intagram />
              </Link>
            </div>
          </div>
          <div className='col-md-4'>
            <h4 className='h6 mb-3'>Contáctenos</h4>
            <ul className='list-unstyled'>
              <li className='d-flex align-items-center mb-2 gap-2'>
                <Location />
                Calle 70 Norte, Cali, Valle del Cauca
              </li>
              <li className='d-flex align-items-center mb-2 gap-2'>
                <Phone />
                312 5594482
              </li>
              <li className='d-flex align-items-center gap-2'>
                <Email />
                proyecto@q-extreme.com
              </li>
            </ul>
          </div>
          <div className='col-md-4'>
            <h4 className='h6 mb-3'>Ubicación</h4>
            <div className='ratio ratio-4x3'>
              <iframe
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1991.2107970898319!2d-76.50815019065182!3d3.4893775155671674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e30a9b50300043b%3A0xf7540a8e25a6356e!2sQ-Extreme!5e0!3m2!1ses-419!2sco!4v1696650689713!5m2!1ses-419!2sco'
                allowFullScreen
                loading='lazy'
                referrerPolicy='no-referrer-when-downgrade'
              />
            </div>
          </div>
        </div>
        <div className='text-center mt-4'>
          <Link to='/politicas-de-datos' className='text-light fw-semibold'>
            Política de Tratamiento de Datos
          </Link>
        </div>
        <div className='text-center mt-4 pt-3 border-top border-light'>
          <p>&copy; {new Date().getFullYear()} Q-Extreme. Todos los derechos reservados.</p>
        </div>
      </div>
    </footer>
  )
}
