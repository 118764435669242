import React from 'react'

import fondoemprender from '../recursos/Logo-SENA-FE-hoja-blanca.jpeg'
import costobeneficio from '../recursos/costobeneficio.png'
import fondoescudocolombia from '../recursos/colombia.png'

export const Nosotros = () => {
  return (
    <div className='container-fluid p-0 '>
      <section className='container-fluid col-md-12 App-section   '>
        <div className='header-video3 '>
          <div className='hero3'>
            <div className='cube3'></div>
            <div className='cube3'></div>
            <div className='cube3'></div>
            <div className='cube3'></div>
            <div className='cube3'></div>
            <div className='cube3'></div>
          </div>
        </div>

        <div className='container-xxl col-md-12 row p-4 '>
          <div className='Space'></div>
          <div data-aos='zoom-in' data-aos-duration='1000' className='col-md-12 text-center '>
            <span className='display-1 fw-bolder col-md-12 titleC T'> ¿QUIENES SOMOS?</span>
          </div>
        </div>
      </section>
      <section
        data-aos='zoom-in'
        className='container-fluid mt-5 p-5 col-12 Bg-section-O text-center shadow-lg d-flex justify-content-center'
      >
        <div className='bg-white row shadow-lg col-12 col-md-10 container-xxl'>
          <div className=' col-12 col-sm-12 col-md-12 col-xl-8 p-5 text-center'>
            <span className='display-6 col-12 col-sm-12 col-md-12 col-xl-12 mt-5 '>
              Nuestra empresa
            </span>
            <p className=' col-12 col-sm-12 col-md-12 mt-5 PT'>
              Q-extreme sas es una empresa orgullosamente caleña fundada en febrero del 2021 gracias
              a ser ganadora de la convocatoria 76 para personas con discapacidad del Fondo
              Emprender del Sena.
            </p>
            <p className=' col-12 col-sm-12 col-md-12 mt-2 PT'>
              Nos especializamos en pruebas de software y modelamiento de procesos de negocio, estos
              servicios los préstamos de manera remota, lo que nos permite ser flexibles y
              adaptarnos a las necesidades de cada uno de nuestros clientes. Para aquellas
              organizaciones que por primera vez quieren implementar buenas prácticas en sus
              proyectos de desarrollo de software o desean redefinir su estrategia actual de QA para
              ajustarla a sus necesidades, les ofrecemos asesoría y acompañamiento personalizado.
            </p>
            <p className=' col-12 col-sm-12 col-md-12 mt-2 PT'>
              Nos motiva el ser una empresa disruptiva y flexible y así poder colocar el QA al
              alcance de todos los proyectos de desarrollo y transformación digital que lo requieran
            </p>
          </div>

          <div className='container-fluid col-12 col-sm-12 col-md-8 col-xl-4 p-5'>
            <a href='./Contactanos' className='text-decoration-none  PT'>
              <button className='hoverbutton  col-12 col-sm-7 col-md-12 col-xl-8 text-center shadow p-2  rounded-5  mt-5'>
                Comunicate con nosotros
              </button>
            </a>
            <div className='mt-5'>
              <img src={fondoemprender} className='w-50' />
              <img src={fondoescudocolombia} className='w-25' />
            </div>
          </div>
        </div>
      </section>
      <section className='d-flex justify-content-center container-xxl' id='obj'>
        <div className=' col-12 p-5 d-flex justify-content-center '>
          <div className=' row col-12 p-5 container-fluid'>
            <span className='display-5 col-md-12 mb-5 text-center'>
              OBJETIVOS CON NUESTROS CLIENTES
            </span>

            <div className='mt-2  col-12 col-xxl-6'>
              <span className='PT'>- Tercerización de áreas de QA y pruebas de software.</span>
            </div>
            <div className='mt-2 col-12 col-xxl-6'>
              <span className='PT'>- Fortalecimiento de áreas de pruebas de software. </span>
            </div>
            <div className='mt-2 col-12 col-xxl-6'>
              <span className='PT'>
                - Alistamiento de procesos y necesidades de usuario (UX/UI) para equipos de
                desarrollo.
              </span>
            </div>
            <div className='mt-2 col-12 col-xxl-6'>
              <span className='PT'>
                - Modelamiento de procesos de negocio orientados al crecimiento y transformación
                digital de las organizaciones.
              </span>
            </div>
            <div className='mt-2 col-12 col-xxl-6'>
              <span className='PT'>
                - Definición de PMV y desarrollo personalizado de software a través de nuestros
                aliados con tecnologías de punta.
              </span>
            </div>
          </div>
        </div>
      </section>

      <section data-aos='zoom-in' className='m-0 container-xxl mx-auto'>
        <div className='row container-fluid p-0 m-0'>
          <div className='col-12 col-md-6 col-xl-8  mt-2 p-5 '>
            <div className='col-12 col-md-12 col-sm-12 col-xl-12 p-5 '>
              <span className='Tg '>Costo – beneficio </span>
            </div>
            <div className='col-md-12 col-sm-12 col-xl-8 p-5 '>
              <p className='pt '>
                La tercerización de las pruebas de software a menudo resulta en ahorros
                significativos en comparación con la contratación y capacitación de personal
                interno. Las empresas pueden evitar gastos en la infraestructura necesaria, la
                adquisición de herramientas y la gestión de un equipo de pruebas a tiempo completo.
              </p>
            </div>
          </div>
          <div className='col-12 col-md-6 col-xl-4 p-0 m-0'>
            <img src={costobeneficio} alt='' className='w-100' />
          </div>
        </div>
      </section>
    </div>
  )
}
export default Nosotros
