import React from 'react';
// import logo from '../recursos/logodinamico.gif';
import logo from '../recursos/logoP.png';

import { Link } from 'react-router-dom';

export const Menuprincipal = () => {
  return (
    <div className="text-center p-0 m-0 Nav">
      <nav
        className="navbar navbar-expand-lg w-100 border-4 border-bottom"
        aria-label="Menú principal de navegación de Q-extreme"
      >
        <div className="container-xxl d-flex justify-content-center align-items-center gap-3">
          {/* Logo de navegación */}
          <Link to="/Inicio" className="align-self-start" aria-label="Inicio, página principal">
            <div>
              <img
                src={logo}
                width={150}
                alt="Logo de Q-extreme"
              />
            </div>
          </Link>

          {/* Botón de toggler para menú móvil */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Abrir menú de navegación"
          >
            <i className="bi bi-list text-dark"></i>
          </button>

          {/* Opciones del menú */}
          <div
            className="collapse navbar-collapse col-md-9 justify-content-end"
            id="navbarTogglerDemo01"
          >
            <ul
              className="navbar-nav mb-2 row mt-2 col-md-10 d-flex justify-content-end"
              role="menu"
            >
              <li className="nav-item col-md-2 border-end" role="menuitem">
                <Link to="/Inicio" className="Nav">
                  Inicio
                </Link>
              </li>
              <li className="nav-item col-md-2 border-end" role="menuitem">
                <Link to="/nosotros" className="Nav">
                  Nosotros
                </Link>
              </li>
              <li
                className="nav-item dropdown col-md-3 border-end"
                role="menuitem"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <Link
                  className="Nav dropdown-toggle"
                  to="/nuestros_servicios"
                  data-bs-toggle="dropdown"
                  aria-label="Abrir submenú de Nuestros servicios"
                >
                  Nuestros servicios
                </Link>
                <ul
                  className="dropdown-menu mt-3 w-100"
                  role="menu"
                  aria-label="Submenú de servicios"
                >
                  <li role="menuitem">
                    <Link className="dropdown-item Nav" to="/nuestros_servicios">
                      Pruebas de software
                    </Link>
                  </li>
                  <li aria-hidden="true">
                    <hr className="dropdown-divider" />
                  </li>
                  <li role="menuitem">
                    <Link className="dropdown-item Nav" to="/nuestros_servicios">
                      Modelamientos de procesos
                    </Link>
                  </li>
                  <li aria-hidden="true">
                    <hr className="dropdown-divider" />
                  </li>
                  <li role="menuitem">
                    <Link className="dropdown-item Nav" to="/nuestros_servicios">
                      Experiencia de usuario
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item col-md-2" role="menuitem">
                <Link className="Nav" to="/contactanos">
                  Contáctanos
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};
