import React from 'react'
import { Link } from 'react-router-dom'

const CardServicios = ({ titulo, descripcion, beneficios, rutaImg }) => {
  return (
    <div className='col-md-10' data-aos="fade-right" >
      <div className='card shadow-sm border-1 overflow-hidden'>
        <div className='w-100' style={{ height: 4, backgroundColor: '#43a8d2' }}></div>
        <div className='card-body align-items-start '>
          <img src={rutaImg} alt='' className='float-start me-4' width={60} />

          <div className=''>
            <h5 className='card-title text-start' style={{ color: '#242873' }}>
              {titulo}
            </h5>
            <p className='card-text text-start'>{descripcion}</p>
            <h6 className='text-start'>Beneficios:</h6>
            <ul className='row list-unstyled text-start'>
              {beneficios?.map((b) => (
                <li className='col-12 col-lg-6'>
                  <i className='bi bi-check-circle text-success me-2' color='#9DBF21'></i>
                  {b}
                </li>
              ))}
            </ul>
            <div className='w-100 d-flex'>
              <Link
                to='/contactanos'
                className='btn mt-3 text-white'
                style={{ backgroundColor: '#43a8d2' }}
              >
                Contáctanos
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardServicios
