import React from 'react'
import fondo from '../recursos/lineas_q-e_reversa.mp4'
import funcionales from '../recursos/ics_Pruebas_Funcionales.png'
import modelamiento from '../recursos/ics_Modelamiento.png'
import accesibilidad from '../recursos/ics_Acc_Usa.png'
import carga from '../recursos/ics_Pruebas_Carga.png'
import automatizadas from '../recursos/ics_Automatizacion.png'
import { animated, config, useSpring } from '@react-spring/web'

import { useState } from 'react'
import CardServicios from './CardServicios'

const DATA_SERVICIOS = [
  {
    titulo: 'Pruebas funcionales',
    descripcion:
      'Las pruebas funcionales se realizan en una variedad de etapas del ciclo de vida del software, desde la fase de diseño hasta la fase de producción. En la fase de diseño, las pruebas funcionales se utilizan para validar los requisitos del software. En la fase de desarrollo, las pruebas funcionales se utilizan para verificar que el software implementa los requisitos correctamente. En la fase de prueba, las pruebas funcionales se utilizan para identificar y corregir errores en el software',
    beneficios: [
      'Asegura de que el software está libre de defectos.',
      'Garantiza el cumplimiento de todas las funcionalidades.',
      'Garantiza que la arquitectura sea correcta y mejora la calidad del producto.',
      'Minimiza los riesgos organizacionales asociados con el software.'
    ],
    rutaImg: funcionales
  },
  {
    titulo: 'Pruebas de carga',
    descripcion:
      'Las pruebas de carga son un tipo de pruebas de rendimiento que se centran en verificar cómo un sistema se comporta bajo una carga pesada. En otras palabras, las pruebas de carga se aseguran de que un sistema puede manejar un volumen de tráfico esperado sin degradar su rendimiento. Las pruebas de carga se pueden realizar en cualquier tipo de sistema, desde sitios web y aplicaciones web hasta bases de datos y sistemas distribuidos. El objetivo de las pruebas de carga es identificar los cuellos de botella en el sistema y garantizar que el sistema pueda manejar la carga esperada sin afectar la experiencia del usuario.',
    beneficios: [
      'Mejora la fiabilidad de la aplicación.',
      'Garantiza la escalabilidad.',
      'Mejora la experiencia del usuario',
      'Mejora la eficiencia',
      'Reduce los costes'
    ],
    rutaImg: carga
  },
  {
    titulo: 'Pruebas automatizadas',
    descripcion:
      'Las pruebas automatizadas son una parte esencial en el ciclo de desarrollo de software. Este proceso implica el uso de herramientas y scripts para ejecutar pruebas de manera sistemática y repetitiva, sin la intervención manual constante. Esto ofrece una serie de beneficios cruciales para los equipos de desarrollo y las organizaciones en general.',
    beneficios: [
      'Reduce tiempos y costos',
      'Aumenta la productividad de los equipos de trabajo',
      'Se consigue un resultado más preciso',
      'Rápida Retroalimentación',
      'Regresión y Mantenimiento'
    ],
    rutaImg: automatizadas
  },
  {
    titulo: 'Pruebas de accesibilidad y usabilidad',
    descripcion:
      'Las pruebas de usabilidad y accesibilidad son dos tipos de pruebas de software que se centran en diferentes aspectos de la experiencia del usuario. Las pruebas de usabilidad se centran en verificar que el software es fácil de usar y comprender, mientras que las pruebas de accesibilidad se centran en verificar que el software es accesible para personas con discapacidades.',
    beneficios: [
      'Incrementa la cuota de mercado y audiencia',
      'Mejora los resultados en los buscadores',
      'Permite abordar problemas del producto, antes de gastar dinero en un diseño deficiente',
      'Optimiza la experiencia de todos los usuarios'
    ],
    rutaImg: accesibilidad
  },
  {
    titulo: 'Modelamiento de procesos de negocio y necesidades de usuario',
    descripcion:
      'El modelado de procesos de negocio y el modelado de necesidades de usuario están estrechamente relacionados. El modelado de procesos de negocio puede ayudar a las organizaciones a comprender las necesidades de los usuarios, mientras que el modelado de necesidades de usuario puede ayudar a las organizaciones a diseñar procesos de negocio que satisfagan esas necesidades.',
    beneficios: [
      'Este servicio les permite a nuestros clientes conocer las necesidades de sus usuarios(UX/UI) y así poder tomar mejores decisiones respecto al producto que requiere desarrollar o adquirir'
    ],
    rutaImg: modelamiento
  }
]

export const Nuestros_servicios = (props) => {
  const { obj, index } = props
  const [opcion, setOpcion] = useState(false)
  const [opcion2, setOpcion2] = useState(false)

  const btnvermas = useSpring({
    from: { height: opcion ? '55px' : '60px', width: opcion ? '80%' : '90%' },
    to: { height: opcion ? '60px' : '55px', width: opcion ? '90%' : '80%' },
    config: { mass: 1, tension: 120, friction: 14 }
  })
  const btnconocenos = useSpring({
    from: { height: opcion2 ? '55px' : '60px', width: opcion2 ? '85%' : '95%' },
    to: { height: opcion2 ? '60px' : '55px', width: opcion2 ? '95%' : '85%' },
    config: { mass: 1, tension: 120, friction: 14 }
  })
  return (
    <div className='container-xxl row p-1 text-center mx-auto'>
      <section className='container-fluid col-md-12 App-section   '>
        <div className='header-video3 '>
          <div className='hero3'>
            <div className='cube3'></div>
            <div className='cube3'></div>
            <div className='cube3'></div>
            <div className='cube3'></div>
            <div className='cube3'></div>
            <div className='cube3'></div>
          </div>
        </div>

        <div className='container-fluid col-md-12 row p-4 '>
          <div className='Space'></div>
          <div data-aos='zoom-in' data-aos-duration='1000' className='col-md-12 text-center '>
            <span className='display-1 fw-bolder col-md-12 titleC T'> PRUEBAS DE SOFTWARE</span>
          </div>
        </div>
      </section>
      <div className='container-fluid col-xl-12 row p-2 mx-auto'>
        <div className='container py-4 d-flex flex-column gap-5'>
          {DATA_SERVICIOS?.map((servicio, i) => (
            <div className={`d-flex justify-content-center`}>
              <CardServicios
                
                beneficios={servicio?.beneficios}
                descripcion={servicio?.descripcion}
                titulo={servicio?.titulo}
                rutaImg={servicio.rutaImg}
                key={servicio.titulo}
              />
            </div>
          ))}
        </div>
        <div className='col-md-3 container-fluid'></div>
      </div>
    </div>
  )
}

export default Nuestros_servicios
