// import React, { createRef } from 'react'
// import ReCAPTCHA from 'react-google-recaptcha'

// import lg_facebook from '../recursos/ic_facebook (2).png'
// import lg_instagram from '../recursos/ic_instagram.png'
// import lg_whatsapp from '../recursos/ic_whatsapp.png'
// import lg_linkendin from '../recursos/ic_linkedin.png'
// import contacto from '../recursos/contactenos.png'
// import { useForm } from '../hooks/useForm'
// import { animated, config, useSpring } from '@react-spring/web'

// import { useState } from 'react'
// import Datos from './leyDatos'

// const initialForm = {
//   name: '',
//   email: '',
//   number: '',
//   message: ''
// }

// const validationForm = (form) => {
//   let errors = {}
//   let regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/
//   let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/
//   let regexComments = /^.{1,255}$/

//   if (!form.name.trim()) {
//     errors.name = "El campo 'Nombre' es requerido"
//   } else if (!regexName.test(form.name.trim())) {
//     errors.name = "El campo 'Nombre' Solo acepta letras y espacios en blanco"
//   } else if (!form.email.trim()) {
//     errors.email = "El campo 'Correo' es requerido"
//   } else if (!regexEmail.test(form.email.trim())) {
//     errors.email = "El campo 'Correo' Solo acepta letras y espacios en blanco"
//   } else if (!form.number.trim()) {
//     errors.number = "El campo 'Numero de contacto' es requerido"
//   } else if (!form.message.trim()) {
//     errors.message = "El campo 'Mensaje' es requerido"
//   } else if (!regexComments.test(form.message.trim())) {
//     errors.message = "El campo 'Mensaje' Solo permite 255 caracteres"
//   } else if (form.ley) {
//   } else {
//     errors.ley = "El campo 'Ley de tratamiento de datos' es requerido"
//   }

//   return errors
// }
// let styles = {
//   fontWeight: 'boldier',
//   color: '#dc3545'
// }

// export const ContactForm = (props) => {
//   const captcha = createRef(null)
//   const Onchange = () => {
//     if (captcha.current.getValue()) {
//     }
//   }
//   const { form, errors, handleBlur, handleChange, handleSubmit } = useForm(
//     initialForm,
//     validationForm
//   )

//   const { obj, index } = props
//   const [opcion, setOpcion] = useState(false)
//   const [opcion2, setOpcion2] = useState(false)
//   const [opcion3, setOpcion3] = useState(false)
//   const [opcion4, setOpcion4] = useState(false)

//   const btnredes = useSpring({
//     from: { height: opcion ? '150px' : '160px', width: opcion ? '90%' : '95%' },
//     to: { height: opcion ? '160px' : '150px', width: opcion ? '95%' : '90%' },
//     config: { mass: 1, tension: 120, friction: 14 }
//   })
//   const btnredes2 = useSpring({
//     from: { height: opcion2 ? '150px' : '160px', width: opcion2 ? '90%' : '95%' },
//     to: { height: opcion2 ? '160px' : '150px', width: opcion2 ? '95%' : '90%' },
//     config: { mass: 1, tension: 120, friction: 14 }
//   })
//   const btnredes3 = useSpring({
//     from: { height: opcion3 ? '150px' : '160px', width: opcion3 ? '90%' : '95%' },
//     to: { height: opcion3 ? '160px' : '150px', width: opcion3 ? '95%' : '90%' },
//     config: { mass: 1, tension: 120, friction: 14 }
//   })
//   const btnredes4 = useSpring({
//     from: { height: opcion4 ? '150px' : '160px', width: opcion4 ? '90%' : '95%' },
//     to: { height: opcion4 ? '160px' : '150px', width: opcion4 ? '95%' : '90%' },
//     config: { mass: 1, tension: 120, friction: 14 }
//   })

//   return (
//     <div className='container-fluid bgcontact'>
//       <div className=' Spaceform2'></div>
//       <div className=' col-xl-12 shadow-lg bg-white m-1 rounded border row '>
//         <div className='col-xl-12 p-3'></div>

//         <div data-aos='zoom-in' data-aos-duration='2000' className='col-xl-6 row '>
//           <img src={contacto} className='borderimg ' />
//         </div>

//         <section
//           data-aos='zoom-in'
//           data-aos-duration='2000'
//           className='container container-sm container-fluid  col-xl-6 d-block mb-5'
//         >
//           <div className='container container-sm  container-fluid'>
//             <form ref={form} onSubmit={handleSubmit} className='row  col-md-12'>
//               <div className='container container-sm container-fluid col-md-12 row mb-5 text-center'>
//                 <span className=' col-md-12 T contactoT mt-5 '>CONTACTENOS</span>
//                 <div className='container col-md-3 border-1 border-bottom border-secondary'></div>
//               </div>
//               <div className='col-md-6'>
//                 <div>
//                   <label className='form-label '>Nombres:*</label>
//                   {errors.name && (
//                     <p className=' text-val' >
//                       {errors.name}
//                     </p>
//                   )}
//                   <input
//                     type='text'
//                     name='name'
//                     placeholder='Escriba sus nombres y apellidos'
//                     className='form-control mt-3 shadow-lg'
//                     onBlur={handleBlur}
//                     onChange={handleChange}
//                     value={form.name}
//                     required
//                   />
//                 </div>
//                 <div className=''>
//                   <label className='form-label mt-3 '>Email:*</label>
//                   {errors.email && (
//                     <p className='text-val' >
//                       {errors.email}
//                     </p>
//                   )}
//                   <input
//                     type='email'
//                     name='email'
//                     placeholder='Escriba su correo'
//                     className='form-control mt-3 shadow-lg'
//                     onBlur={handleBlur}
//                     onChange={handleChange}
//                     value={form.email}
//                     required
//                   />
//                 </div>
//                 <div className=''>
//                   <label className='form-label mt-3 '>Numero de contacto:*</label>
//                   {errors.number && (
//                     <p className=' text-val' >
//                       {errors.number}
//                     </p>
//                   )}
//                   <input
//                     type='text'
//                     name='number'
//                     placeholder='Escriba su numero de teléfono'
//                     className='form-control mt-3 shadow-lg'
//                     onBlur={handleBlur}
//                     onChange={handleChange}
//                     value={form.number}
//                     required
//                   />
//                 </div>
//               </div>

//               <div className='col-md-6 '>
//                 <label className='form-label  '>Mensaje:*</label>
//                 {errors.message && (
//                   <p className=' text-val' >
//                     {errors.message}
//                   </p>
//                 )}
//                 <textarea
//                   name='message'
//                   placeholder='Escriba su consulta'
//                   className='form-control mt-3 h-25 shadow-lg'
//                   onBlur={handleBlur}
//                   onChange={handleChange}
//                   value={form.message}
//                   required
//                 />

//                 <input
//                   type='checkbox'
//                   name='ley'
//                   onBlur={handleBlur}
//                   onChange={handleChange}
//                   checked={form.ley}
//                   required
//                 />
//                 <label className='form-label mt-3 '>
//                   Ley de tratamiento de datos{' '}
//                   <a href='./leyDatos' target='_blank'>
//                     1581 de 2012:
//                   </a>
//                   *
//                 </label>
//                 {errors.ley && (
//                   <p className=' text-val' >
//                     {errors.ley}
//                   </p>
//                 )}

//                 <div className='text-center'>
//                   <ReCAPTCHA
//                     ref={captcha}
//                     sitekey='6LeyRfEnAAAAADWSBEQ3DqvWu0hXiyVeeM6-t3fp'
//                     onChange={Onchange}
//                   />
//                   <input
//                     type='submit'
//                     value='Solicitar Información'
//                     className='shadow  rounded-5 hoverbutton p-3 mt-5 '
//                   />{' '}
//                 </div>
//               </div>
//             </form>
//           </div>
//         </section>

//         <section className='container-fluid col-12 col-sm-12 col-md-12  col-xl-8  mt-2 text-center'>
//           <div className=' row col-xl-12 tam justify-content-center'>
//             <div className='col-7  col-sm-2 mb-4 mt-4 row col-3 col-sm-4 col-md-3 col-xl-3'>
//               <animated.div
//                 data-aos='flip-left'
//                 data-aos-easing='ease-out-cubic'
//                 data-aos-duration='2000'
//                 className='col-5 col-sm-2 col-xl-3 rounded-5 boxredes boxdesignW'
//                 onMouseOver={() => {
//                   setOpcion(true)
//                 }}
//                 onMouseOut={() => {
//                   setOpcion(false)
//                 }}
//                 style={btnredes}
//               >
//                 <a
//                   href='https://api.whatsapp.com/send?phone=573125594482'
//                   target='_blank'
//                   className='text-decoration-none text-black'
//                   rel='noreferrer'
//                 >
//                   <div className='mb-4 mt-4'>
//                     <img src={lg_whatsapp} className='shadow-lg rounded-circle mx-auto d-block' />
//                   </div>

//                   <span className='redes'>Whatsapp</span>
//                 </a>
//               </animated.div>
//             </div>
//             <div className='col-7 col-sm-2 mb-4 mt-4 row col-5 col-sm-4 col-md-3 col-xl-3'>
//               <animated.div
//                 data-aos='flip-left'
//                 data-aos-easing='ease-out-cubic'
//                 data-aos-duration='2000'
//                 className='col-xl-3 rounded-5 boxredes boxdesignF'
//                 onMouseOver={() => {
//                   setOpcion2(true)
//                 }}
//                 onMouseOut={() => {
//                   setOpcion2(false)
//                 }}
//                 style={btnredes2}
//               >
//                 <a
//                   href='https://www.facebook.com/Qextreme'
//                   target='_blank'
//                   className='text-decoration-none text-black'
//                   rel='noreferrer'
//                 >
//                   <div className='mb-4 mt-4'>
//                     <img src={lg_facebook} className='shadow-lg mx-auto d-block' />
//                   </div>
//                   <span className='redes'>Q-Extreme</span>
//                 </a>
//               </animated.div>
//             </div>
//             <div className='col-7  col-sm-2 mb-4 mt-4 row col-5 col-sm-4 col-md-3 col-xl-3'>
//               <animated.div
//                 data-aos='flip-left'
//                 data-aos-easing='ease-out-cubic'
//                 data-aos-duration='2000'
//                 className='col-xl-3 rounded-5 boxredes boxdesignI'
//                 onMouseOver={() => {
//                   setOpcion3(true)
//                 }}
//                 onMouseOut={() => {
//                   setOpcion3(false)
//                 }}
//                 style={btnredes3}
//               >
//                 <a
//                   href='https://www.instagram.com/qextremetecnology/?igshid=NzZhOTFlYzFmZQ%3D%3D'
//                   target='_blank'
//                   className='text-decoration-none text-black'
//                   rel='noreferrer'
//                 >
//                   <div className='mb-4 mt-4'>
//                     <img src={lg_instagram} className='shadow-lg rounded-4 mx-auto d-block' />
//                   </div>
//                   <span className='redes'>qextremetecnology</span>
//                 </a>
//               </animated.div>
//             </div>
//             <div className='col-7  col-sm-2 mb-4 mt-4 row col-3 col-sm-4 col-md-3 col-xl-3'>
//               <animated.div
//                 data-aos='flip-left'
//                 data-aos-easing='ease-out-cubic'
//                 data-aos-duration='2000'
//                 className='col-xl-3 rounded-5 boxredes boxdesignF'
//                 onMouseOver={() => {
//                   setOpcion4(true)
//                 }}
//                 onMouseOut={() => {
//                   setOpcion4(false)
//                 }}
//                 style={btnredes4}
//               >
//                 <a
//                   href='https://www.linkedin.com/company/q-extreme/'
//                   target='_blank'
//                   className='text-decoration-none text-black'
//                   rel='noreferrer'
//                 >
//                   <div className='mb-4 mt-4'>
//                     <img src={lg_linkendin} className='shadow-lg  mx-auto d-block' />
//                   </div>
//                   <span className='redes'>Q-Extreme</span>
//                 </a>
//               </animated.div>
//             </div>
//           </div>
//         </section>
//       </div>
//     </div>
//   )
// }

// export default ContactForm

// ------------------------

import React, { createRef } from 'react'
import contacto from '../recursos/contactenos.png'

import { useForm } from '../hooks/useForm'
import ReCAPTCHA from 'react-google-recaptcha'

export default function ContactForm() {
  const captcha = createRef(null)
  const Onchange = () => {
    if (captcha.current.getValue()) {
    }
  }

  const validationForm = (form) => {
    let errors = {}
    let regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/
    let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/
    let regexComments = /^.{1,255}$/

    if (!form.name.trim()) {
      errors.name = "El campo 'Nombre' es requerido"
    } else if (!regexName.test(form.name.trim())) {
      errors.name = "El campo 'Nombre' Solo acepta letras y espacios en blanco"
    } else if (!form.email.trim()) {
      errors.email = "El campo 'Correo' es requerido"
    } else if (!regexEmail.test(form.email.trim())) {
      errors.email = "El campo 'Correo' Solo acepta letras y espacios en blanco"
    } else if (!form.number.trim()) {
      errors.number = "El campo 'Numero de contacto' es requerido"
    } else if (!form.message.trim()) {
      errors.message = "El campo 'Mensaje' es requerido"
    } else if (!regexComments.test(form.message.trim())) {
      errors.message = "El campo 'Mensaje' Solo permite 255 caracteres"
    } else if (form.ley) {
    } else {
      errors.ley = "El campo 'Ley de tratamiento de datos' es requerido"
    }

    return errors
  }

  const initialForm = {
    name: '',
    email: '',
    number: '',
    message: ''
  }

  const { form, errors, handleBlur, handleSubmit, handleChange } = useForm(
    initialForm,
    validationForm
  )

  return (
    <div className='container-fluid  '>
      <div className=' col-xl-12 shadow-lg bg-white m-1 rounded border row '>
        <div className='col-xl-12 p-3'></div>

        <div
          data-aos='zoom-in'
          data-aos-duration='2000'
          className='col-xl-6 row '
          style={{ display: 'flex', alignItems:'center', justifyContent:'center'}}
        >
          <img src={contacto} className='borderimg ' height={500} />
        </div>

        <section
          data-aos='zoom-in'
          data-aos-duration='2000'
          className='container container-sm container-fluid  col-xl-6 d-block mb-5'
        >
          <div className='container container-sm  container-fluid'>
            <form ref={form} onSubmit={handleSubmit}>
              <div className='container container-sm container-fluid col-md-12 row mb-5 text-center'>
                <span className=' col-md-12 T contactoT mt-5 '>CONTACTENOS</span>
              </div>
              <div className='form-floating mb-3'>
                <input
                  type='text'
                  name='name'
                  id='name'
                  className='form-control'
                  value={form.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder='Nombres y apellidos *'
                  required
                />
                <label for='name'>Nombres y apellidos *</label>
              </div>
              <div className='form-floating mb-3'>
                <input
                  type='email'
                  name='email'
                  id='email'
                  className='form-control'
                  value={form.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder='Escriba su correo electrónico *'
                  required
                />
                <label for='email'>Correo electrónico *</label>
              </div>

              <div className='form-floating mb-3'>
                <input
                  type='text'
                  name='number'
                  id='number'
                  className='form-control'
                  value={form.number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder='Escriba su número de teléfono'
                  required
                />
                <label for='number'>Número de teléfono *</label>
              </div>

              <div className='form-floating mb-3'>
                <textarea
                  name='message'
                  id='message'
                  value={form.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className='form-control'
                  placeholder='Escriba su consulta *'
                  rows={4}
                  required
                />
                <label for='message'>Escriba su consulta *</label>
              </div>

              <div className='privacy-checkbox'>
                <input
                  type='checkbox'
                  id='privacy'
                  name='ley'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  checked={form.ley}
                  required
                />
                <label htmlFor='privacy'>
                  Ley de tratamiento de datos <a href='./leyDatos'>LEY DE 2012</a>
                </label>
              </div>

              <ReCAPTCHA
                ref={captcha}
                sitekey='6LeyRfEnAAAAADWSBEQ3DqvWu0hXiyVeeM6-t3fp'
                onChange={Onchange}
              />

              <button className='mt-4' type='submit'>
                Solicitar Información
              </button>
            </form>

            {/* Social Media Links */}
            <div className='social-links'>
              <a
                href='https://api.whatsapp.com/send?phone=573125594482'
                className='social-icon whatsapp'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <path d='M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z'></path>
                </svg>
              </a>
              <a href='https://www.facebook.com/Qextreme' className='social-icon facebook'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <path d='M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z'></path>
                </svg>
              </a>
              <a
                href='https://www.instagram.com/qextremetecnology/?igshid=NzZhOTFlYzFmZQ%3D%3D'
                className='social-icon instagram'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <rect x='2' y='2' width='20' height='20' rx='5' ry='5'></rect>
                  <path d='M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z'></path>
                  <line x1='17.5' y1='6.5' x2='17.51' y2='6.5'></line>
                </svg>
              </a>
              <a
                href='https://www.linkedin.com/company/q-extreme/'
                className='social-icon linkedin'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <path d='M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z'></path>
                  <rect x='2' y='9' width='4' height='12'></rect>
                  <circle cx='4' cy='4' r='2'></circle>
                </svg>
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}
