import './App.css'
import './components/ContactForm.css'
import { Menuprincipal } from './components/menu'
import { Footerprincipal } from './components/footer'

import { Route, BrowserRouter, Routes } from 'react-router-dom'
import Inicio from './components/inicio'
import Nosotros from './components/nosotros'
import Nuestros_servicios from './components/nuestros_servicios'
import ContentServices from './components/contentservices'
import ContactForm from './components/ContactForm'

import ScrollToTop from './hooks/ScrollToTop '
import PoliticaDatos from './components/PoliticasDatos'

function App() {
  return (
    <div className=''>
      <BrowserRouter>
        <Menuprincipal />
        <ScrollToTop />
        <div className='min-vh-100 overflow-hidden'>
          <Routes>
            <Route path='/' element={<Inicio />} />
            <Route path='/inicio' element={<Inicio />} />
            <Route path='/nosotros' element={<Nosotros />} />
            <Route path='/nuestros_servicios' element={<Nuestros_servicios />} />
            <Route path='/content-servicios' element={<ContentServices />} />
            <Route path='/contactanos' element={<ContactForm />} />
            <Route path='/politicas-de-datos' element={<PoliticaDatos />} />
          </Routes>
        </div>

        <Footerprincipal />
      </BrowserRouter>

      {/* 
     
    


      </BrowserRouter>
 */}
    </div>
  )
}
export default App
